// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sections: [
    { ns: 'label1', label1: 'hinweisdatensatz', expand: true },
    { ns: 'label1', label1: 'namen', expand: false },
    { ns: 'label1', label1: 'bevorzugte_benennung', expand: false },
    { ns: 'label1', label1: 'titel', expand: false },
    { ns: 'label1', label1: 'geistige_schoepfer', expand: true },
    { ns: 'label1', label1: 'beschreibende_angaben', expand: true },
    { ns: 'label1', label1: 'synonyme', expand: true },
    { ns: 'label1', label1: 'zeit', expand: true },
    { ns: 'label1', label1: 'chronologie', expand: true },
    { ns: 'label1', label1: 'geografischer_bezug', expand: true },
    { ns: 'label1', label1: 'beziehungen_personen', expand: true },
    { ns: 'label1', label1: 'beziehungen_organisationen', expand: true },
    { ns: 'label1', label1: 'bezug_veranstaltungen', expand: true },
    { ns: 'label1', label1: 'bezug_werken_objekten_sammlungen', expand: true },
    { ns: 'label1', label1: 'identifikatoren', expand: true },
    { ns: 'label1', label1: 'oberbegriffe', expand: true },
    { ns: 'label1', label1: 'gnd_systematik', expand: true },
    { ns: 'label1', label1: 'ddc_notationen', expand: true },
    { ns: 'label1', label1: 'thematischer_bezug', expand: true },
    { ns: 'label1', label1: 'andere_normdateien', expand: true },
    { ns: 'label1', label1: 'publikationen', expand: true },
    { ns: 'label1', label1: 'definition', expand: true },
    { ns: 'label1', label1: 'verwendungshinweise', expand: false },
    { ns: 'label1', label1: 'weitere_angaben', expand: false },
    { ns: 'label1', label1: 'quellen_fuer_die_metadaten', expand: false },
    { ns: 'label1', label1: 'katalogisierende_institution', expand: false },
    { ns: 'label1', label1: 'letzte_aenderung_des_datensatzes', expand: false },
//    { ns: 'label1', label1: 'externe_gnd_nutzung', expand: false },
  ],
  content: {
    namen: [
      { label2: 'bevorzugter_name' },
      { label2: 'andere_namen', subfield: '-4' },
      { relations: 'abku|naaf|nafr|nasp|nauv|navo|nawi|ngkd|nswd|pseu|spio' },
      { label2: 'frueherer_bevorzugter_name' },
    ],
    bevorzugte_benennung: [
      { field: '041A' },
//      { label2: 'hinweisdatensatz' },
    ],
    titel: [
      { label2: 'bevorzugter_titel' },
      { label2: 'andere_titel', subfield: '-4' },
      { relations: '*' },
      { label2: 'frueherer_bevorzugter_titel' },
    ],
    geistige_schoepfer: [
      { relations: 'aut1|kom1|kue1' }, // match any of these relations
      { relations: 'auta|koma|kuen' },
      { relations: 'arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd' },
    ],
    beschreibende_angaben: [
      { relations: 'adel' },
      { relations: 'akad' },
      { label2: 'geschlecht' },
      { label2: 'sprachencodes' },
      { relations: 'spra' },
      { relations: 'berc' },
      { relations: 'beru' },
      { relations: 'istr' },
      { label2: 'inhaltstyp' },
      { label2: 'form' },
      { label2: 'besetzung' },
      { label2: 'zaehlungen' },
      { label2: 'tonart' },
      { label2: 'entitaetencode' },
    ],
    synonyme: [{ subfield: '-4' }, { relations: '*' }],
    zeit: [
      { relations: 'datb|datf|datj|datl|dats|datu|datv' },
      { relations: 'datx' },
      { relations: 'datw' },
      { relations: 'datz' },
      { relations: 'rela' },
    ],
    chronologie: [
      { relations: 'vorg' },
      { relations: 'nazw' },
      { relations: 'nach' },
    ],
    geografischer_bezug: [
      { label2: 'laender' },
      { label2: 'koordinaten' },
      { relations: '?' },
    ],
    identifikatoren: [
      { identifiers: 'gnd', field: '007K' },
      { label2: 'gnd', field: '003U' },
      { identifiers: 'orcid' }, // match this identifier value
      { identifiers: 'isni' },
      { identifiers: 'wikidata' },
      { identifiers: 'ror|geonames|opensm|filmport' },
      { label2: 'andere_identifikatoren', field: '070A/02' },
      { label2: 'andere_identifikatoren', field: '006Y' },
      { label2: 'andere_identifikatoren', field: '003@' },
      { label2: 'alte_identifikatoren', field: '007N' },
      { label2: 'alte_identifikatoren', field: '007R' },
      { label2: 'alte_identifikatoren', field: '007W' },
      // { entityFacts: '??' },
    ],
    ddc_notationen: [
      { subfield: 'd', sort: 'desc' },
      { subfield: '-d' },
      { label2: 'ddc_veraltet' },
    ],
    andere_normdateien: [
      { subfield: '-4' },
      { relations: 'ftae' },
      { relations: 'ftaa' },
      { relations: 'ftai' },
      { relations: 'ftao' },
      //{ label2: 'fta_missing' , subfield: '-4' },
      //{ subfield: '>2', sort: 'asc' },
      //{ subfield: '2', sort: 'asc' },
    ],
    publikationen: [
      { subfield: 'f', sort: 'asc' }, // match record with a $f
      { subfield: '-f' }, // match record without a $f
      // { cultureGraph: '??' },
    ],
    verwendungshinweise: [
      { label2: 'benutzungshinweis' },
      { label2: 'teilbestandskennzeichen', subfield: 'a', sort: 'asc' },
      { label2: 'nutzungskennzeichen', subfield: 'a', sort: 'asc' },
    ],
    weitere_angaben: [
      { field: '050G' },
      { label2: 'fruehere_bevorzugte_benennung' },
    ],
    quellen_fuer_die_metadaten: [
      { field: '050E', subfield: 'u' },
      { field: '050E', subfield: '-u' },
      { label2: 'negativ_eingesehene_quellen' },
      { label2: 'katalogisierungsquelle' },
    ],
    katalogisierende_institution: [
      { label2: 'isil' },
      { label2: 'datum_der_ersterfassung' },
      { label2: 'satzart' },
    ],
//    externe_gnd_nutzung: [
//      { label2: 'entity_facts' },
//      { label2: 'publicationen_label2' },
//    ],
  },
  fields: {
    '001A': {
      label1: 'katalogisierende_institution',
      content: {
        ns: 'label2',
        label2: 'datum_der_ersterfassung',
      },
      render: [{ type: 'date', subfield: '>0' }],
    },
    '001B': {
      label1: 'letzte_aenderung_des_datensatzes',
      render: [
        { type: 'date', subfield: '>0' },
        { type: 'additional', subfield: 'M' },
      ],
    },
    '002@': {
      label1: 'katalogisierende_institution',
      content: {
        ns: 'label2',
        label2: 'satzart',
      },
      render: [{ type: 'text', subfield: '0' }],
    },
    '003@': {
      label1: 'identifikatoren',
      content: {
        ns: 'label2',
        label2: 'andere_identifikatoren',
      },
      render: [{ type: 'text', subfield: '0' }],
    },
    '003U': {
      label1: 'identifikatoren',
      content: {
        ns: 'identifierSources',
        label2: 'gnd',
      },
      render: [
        { type: 'link', subfield: 'a', url: 'a', text: 'a' },
        { type: 'additional', subfield: 'z|v' },
      ],
    },
    '004B': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'entitaetencode',
      },
      render: [
        { type: 'text', subfield: 'a' },
        {
          type: 'link',
          search: { entitycode: 'a' },
          tooltip: { ns: 'entityCodes', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'a', ns: 'entityCodes' },
        {
          type: 'info',
          ns: 'entityCodes',
          url: '_info',
          tooltip: '_info',
        },
      ],
    },
    '006Y': {
      label1: 'identifikatoren',
      content: [
        {
          ns: 'identifierSources',
          identifiers: 'orcid|isni|wikidata|ror|geonames|opensm|filmport',
        },
        {
          ns: 'label2',
          label2: 'andere_identifikatoren',
          identifiers: '-orcid|isni|wikidata|ror|geonames|opensm|filmport', // match all identifier except these
        },
      ],
      render: [
        { type: 'label', ns: 'label3', subfield: 'S' },
        { type: 'mixin', mixin: 'orcid-icon', subfield: 'v' },
        {
          type: 'link',
          id: 'M',
          baseUrl: { subfield: 'S', ns: 'identifierSources' },
          text: '0',
          condition: '0:isni', // match only if subfield 0 has values: 'isni',
        },
        {
          type: 'link',
          id: '0',
          baseUrl: { subfield: 'S', ns: 'identifierSources' },
          text: '0',
          condition: '0:-isni|matrix-number|iswc',
        },
        {
          type: 'link',
          id: '0',
          fixedUrl: { ns: 'identifierSources', subfield: 'S' }, // namespace for the fixed link, and the subfield to find the code from.
          text: '0', // field for the displayed text
          condition: 'S:matrix-number|iswc', // optional, match only if subfield S has value 'matrix-number or iswc'
        },
        {
          type: 'link',
          search: { identifikatoren: 'S' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'C|5|v' },
        {
          type: 'info',
          ns: 'identifierSources',
		      url: '_info',
          tooltip: '_info',
          condition: 'v',
        },
      ],
    },
    '007K': {
      label1: 'identifikatoren',
      content: {
        ns: 'identifierSources',
        identifiers: 'gnd',
      },
      render: [{ type: 'text', subfield: '0' }],
    },
    '007N': {
      label1: 'identifikatoren',
      content: {
        ns: 'label2',
        label2: 'alte_identifikatoren',
      },
      render: [
        { type: 'label', ns: 'label3', subfield: 'a' },
        { type: 'text', subfield: '0' },
        {
          type: 'additional',
          subfield: 'v',
          tooltips: [{ subfield: 'v', ns: 'label3' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '007R': {
      label1: 'identifikatoren',
      content: {
        ns: 'label2',
        label2: 'alte_identifikatoren',
      },
      render: [{ type: 'text', subfield: '0' }],
    },
    '007W': {
      label1: 'identifikatoren',
      content: {
        ns: 'label2',
        label2: 'alte_identifikatoren',
      },
      render: [{ type: 'text', subfield: '0' }],
    },
    '008A': {
      label1: 'verwendungshinweise',
      content: {
        ns: 'label2',
        label2: 'teilbestandskennzeichen',
      },
      render: [
        { type: 'text', subfield: 'a' },
        {
          type: 'link',
          search: { teilbestandskennzeichen: 'a' },
          tooltip: { ns: 'partialStock', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'a', ns: 'partialStock' },
      ],
    },
    '008B': {
      label1: 'verwendungshinweise',
      content: {
        ns: 'label2',
        label2: 'nutzungskennzeichen',
      },
      render: [
        { type: 'text', subfield: 'a' },
        {
          type: 'link',
          search: { nutzungskennzeichen: 'a' },
          tooltip: { ns: 'usageIndicator', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'a', ns: 'usageIndicator' },
      ],
    },
    '010E': {
      label1: 'quellen_fuer_die_metadaten',
      content: {
        ns: 'label2',
        label2: 'katalogisierungsquelle',
      },
      render: [
        {
          type: 'text',
          subfield: 'b',
          tooltips: [{ subfield: 'b', ns: 'languageCodes' }],
        },
        { type: 'text', subfield: 'e' },
        { type: 'additional', subfield: 'e', ns: 'catalogingSources' },
        { type: 'text', subfield: 'f' },
        { type: 'additional', subfield: 'f', ns: 'catalogingSources' },
      ],
    },
    '022@': {
      label1: 'titel',
      content: [
        { ns: 'label2', label2: 'andere_titel', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|t|g|m|n|p|o|s|x|f|r|l|h' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [
            { subfield: '4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
      collapseMultipleEntries: true,
    },
    '022A': {
      label1: 'titel',
      content: {
        ns: 'label2',
        label2: 'bevorzugter_titel',
      },
      render: [
        { type: 'text', subfield: 'a|t|g|m|n|p|o|s|x|f|r|l|h' },
        { type: 'additional', subfield: 'L|U|v|Z' },
      ],
    },
    '022P': {
      label1: 'andere_normdateien',
      content: [{ subfield: '-4' }, { ns: 'relationCodes', relations: '*' }],
      render: [
        { type: 'link', url: 'u', text: 'a|t|g|m|n|p|o|s|x|f|r|l|h' },
        { type: 'link', url: '>u', text: '>a|>t|>g|>m|>n|>p|>o|>s|>x|>f|>r|>l|>h' },
        {
          type: 'text',
          subfield: '2|>2',
          tooltips: [{ subfield: '2|>2', ns: 'identifierSources' }],
        },
        {
          type: 'link',
          search: { identifikatoren: '2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { gndmul: '>2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'additional',
          subfield: '4|>4|L|U|S|>S|0|>0|v|>v|Z|>Z',
          tooltips: [
            { subfield: '4|>4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
            { subfield: 'S|>S', ns: 'gndmul' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { relationcodes: '>4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '022R': {
      label1: {
        thematischer_bezug: {
          relations: 'anla|feie|stud|them|vbal',
        },
        oberbegriffe: {
          relations: 'obal|obge|obin|obpa',
        },
        chronologie: {
          relations: 'vorg|nach|nazw',
        },
        bezug_werken_objekten_sammlungen: {
          relations:
            '-anla|feie|stud|them|vbal|obal|obge|obin|obpa|vorg|nach|nazw',
        },
      },
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|t|g|m|n|p|o|s|x|f|r|l|h' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
        {
          type: 'link',
          search: { relation: { code: '4', id: '>9', name: '>name' } },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '028@': {
      label1: 'namen',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'd' },
        { type: 'text', subfield: 'c' },
        { type: 'text', subfield: 'a|P' },
        { type: 'text', subfield: 'n|l|g|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [
            { subfield: '4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
      collapseMultipleEntries: true,
    },
    '028A': {
      label1: 'namen',
      content: {
        ns: 'label2',
        label2: 'bevorzugter_name',
      },
      render: [
        { type: 'text', subfield: 'd' },
        { type: 'text', subfield: 'c' },
        { type: 'text', subfield: 'a|P' },
        { type: 'text', subfield: 'n|l|g|x' },
        { type: 'additional', subfield: 'L|U|v|Z' },
      ],
    },
    '028P': {
      label1: 'andere_normdateien',
      content: [{ subfield: '-4' }, { ns: 'relationCodes', relations: '*' }],
      render: [
        { type: 'text', subfield: 'd' },
        { type: 'text', subfield: 'c' },
        { type: 'text', subfield: 'a|P' },
        { type: 'text', subfield: 'n|l|g|x' },
        { type: 'link', url: '>u', text: '>d|>c|>a|>P|>n|>l|>g|>x' },
        { type: 'link', url: 'u', text: 'u' },
        {
          type: 'text',
          subfield: '2|>2',
          tooltips: [{ subfield: '2|>2', ns: 'identifierSources' }],
        },
        {
          type: 'link',
          search: { identifikatoren: '2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { gndmul: '>2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'additional',
          subfield: '4|>4|L|U|S|>S|0|>0|v|>v|Z|>Z',
          tooltips: [
            { subfield: '4|>4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
            { subfield: 'S|>S', ns: 'gndmul' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { relationcodes: '>4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '028R': {
      label1: {
        geistige_schoepfer: {
          relations:
            'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
        },
        namen: {
          relations: 'nawi|pseu',
        },
        oberbegriffe: {
          relations: 'obal|obge|obin|obpa',
        },
        thematischer_bezug: {
          relations: 'anla|feie|rela|stud|them|vbal',
        },
        beziehungen_personen: {
          relations:
            '-aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd|nawi|pseu|obal|obge|obin|obpa|anla|feie|rela|stud|them|vbal',
        },
      },
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|P|a|d|c|n|l|g|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
        {
          type: 'link',
          search: { relation: { code: '4', id: '>9', name: '>name' } },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '029@': {
      label1: 'namen',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|n|g|b|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [
            { subfield: '4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
      collapseMultipleEntries: true,
    },
    '029A': {
      label1: 'namen',
      content: {
        ns: 'label2',
        label2: 'bevorzugter_name',
      },
      render: [
        { type: 'text', subfield: 'a|n|g|b|x' },
        { type: 'additional', subfield: 'L|U|v|Z' },
      ],
    },
    '029P': {
      label1: 'andere_normdateien',
      content: [{ subfield: '-4' }, { ns: 'relationCodes', relations: '*' }],
      render: [
        { type: 'link', url: 'u', text: 'a|n|g|b|x' },
        { type: 'link', url: '>u', text: '>a|>n|>g|>b|>x' },
        {
          type: 'text',
          subfield: '2|>2',
          tooltips: [{ subfield: '2|>2', ns: 'identifierSources' }],
        },
        {
          type: 'link',
          search: { identifikatoren: '2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { gndmul: '>2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'additional',
          subfield: '4|>4|L|U|S|>S|0|>0|v|>v|Z|>Z',
          tooltips: [
            { subfield: '4|>4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
            { subfield: 'S|>S', ns: 'gndmul' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { relationcodes: '>4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '029R': {
      label1: {
        thematischer_bezug: {
          relations: 'anla|feie|rela|stud|them|vbal',
        },
        oberbegriffe: {
          relations: 'obal|obge|obin|obpa',
        },
        geistige_schoepfer: {
          relations:
            'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
        },
        chronologie: {
          relations: 'vorg|nach|nazw',
        },
        beziehungen_organisationen: {
          relations:
            '-anla|feie|rela|stud|them|vbal|obal|obge|obin|obpa|aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd|vorg|nach|nazw',
        },
      },
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|n|g|b|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
        {
          type: 'link',
          search: { relation: { code: '4', id: '>9', name: '>name' } },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '030@': {
      label1: 'namen',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|n|d|c|g|b|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [
            { subfield: '4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
      collapseMultipleEntries: true,
    },
    '030A': {
      label1: 'namen',
      content: {
        ns: 'label2',
        label2: 'bevorzugter_name',
      },
      render: [
        { type: 'text', subfield: 'a|n|d|c|g|b|x' },
        { type: 'additional', subfield: 'L|U|v|Z' },
      ],
    },
    '030P': {
      label1: 'andere_normdateien',
      content: [{ subfield: '-4' }, { ns: 'relationCodes', relations: '*' }],
      render: [
        { type: 'link', url: 'u', text: 'a|n|d|c|g|b|x' },
        { type: 'link', url: '>u', text: '>a|>n|>d|>c|>g|>b|>x' },
        {
          type: 'text',
          subfield: '2|>2',
          tooltips: [{ subfield: '2|>2', ns: 'identifierSources' }],
        },
        {
          type: 'link',
          search: { identifikatoren: '2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { gndmul: '>2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'additional',
          subfield: '4|>4|L|U|S|>S|0|>0|v|>v|Z|>Z',
          tooltips: [
            { subfield: '4|>4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
            { subfield: 'S|>S', ns: 'gndmul' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { relationcodes: '>4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '030R': {
      label1: {
        thematischer_bezug: {
          relations: 'anla|feie|rela|stud|them|vbal',
        },
        oberbegriffe: {
          relations: 'obal|obge|obin|obpa',
        },
        chronologie: {
          relations: 'vorg|nach|nazw',
        },
        geistige_schoepfer: {
          relations:
            'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
        },
        bezug_veranstaltungen: {
          relations:
            '-anla|feie|rela|stud|them|vbal|obal|obge|obin|obpa|aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd|vorg|nach|nazw',
        },
      },
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|n|d|c|g|b|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
        {
          type: 'link',
          search: { relation: { code: '4', id: '>9', name: '>name' } },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '032L': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'inhaltstyp',
      },
      render: [
        { type: 'text', subfield: 'a' },
        { type: 'additional', subfield: 'b' },
        {
          type: 'link',
          search: { inhaltstyp: 'b' },
          tooltip: { ns: 'contentTypes', key: 'searchlinkTooltip' },
        },
      ],
    },
    '032T': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'geschlecht',
      },
      render: [
        { type: 'text', subfield: 'a' },
        { type: 'additional', subfield: 'a', ns: 'genderCodes' },
        {
          type: 'info',
          ns: 'genderCodes',
          tooltip: '_info',
        },
        { type: 'additional', subfield: 'v' },
      ],
    },
    '032W': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'form',
      },
      render: [
        { type: 'link', id: '>9', text: '>name' },
        { type: 'text', subfield: 'a' },
        {
          type: 'link',
          search: { form: '>name' },
          tooltip: { ns: 'forms', key: 'searchlinkTooltip' },
        },
      ],
    },
    '032X': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'besetzung',
      },
      render: [
        { type: 'link', id: '>9', text: '>name' },
        { type: 'text', subfield: 'a|e|n|p|s|t' },
        {
          type: 'link',
          search: { instrumente_besetzung: '>name' },
          tooltip: { ns: 'musicalInstruments', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'v' },
      ],
    },
    '032Y': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'zaehlungen',
      },
      render: [{ type: 'text', subfield: 'a|b|c' }],
    },
    '032Z': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'tonart',
      },
      render: [
        { type: 'link', id: '>9', text: '>name' },
        {
          type: 'text',
          subfield: 'a',
          tooltips: [{ subfield: 'a', ns: 'musicalKeys' }],
        },
        {
          type: 'link',
          search: { musicalkey: 'a' },
          tooltip: { ns: 'musicalKeys', key: 'searchlinkTooltip' },
        },
        {
          type: 'info',
          ns: 'musicalKeys',
          url: '_info',
          tooltip: '_info',
        },
      ],
    },
    '037G': {
      label1: 'ddc_notationen',
      content: [{ subfield: 'd', values: '4|3|2|1' }, { subfield: '-d' }],
      render: [
        {
          type: 'link',
          id: 'c',
          baseUrl: {
            ns: 'ddcNotations',
            key: 'webDeweySearch',
          },
          text: 'c',
          tooltip: { ns: 'ddcNotations', key: 'webDeweySearch' },
        },
        {
          type: 'link',
          search: { ddc_notation: 'c' },
          tooltip: { ns: 'ddcNotations', key: 'searchlinkTooltip' },
        },
        {
          type: 'text',
          subfield: 'd',
          ns: 'ddcNotations',
          tooltips: [{ subfield: 'd', ns: 'ddcNotations' }],
        },
        { type: 'date', subfield: 't|g' },
        { type: 'additional', subfield: 'v' },
      ],
    },
    '037H': {
      label1: 'geografischer_bezug',
      content: {
        ns: 'label2',
        label2: 'koordinaten',
        // subfield: { name: 'A', pos: 1, value: 'a' },
      },
      render: [
        { type: 'text', subfield: 'f' },
        { type: 'text', subfield: 'd' },
        //         {
        //           type: 'text',
        //           subfield: 'd|e|f|g',
        //           condition: 'A:dgx',
        //         },
        { type: 'text', subfield: '3' },
        {
          type: 'link',
          subfield: '2',
          id: '2',
          fixedUrl: { subfield: '2', ns: 'coordinatesCodes' },
          text: '2',
          tooltip: { ns: 'coordinatesCodes', subfield: '2' },
        },
        {
          type: 'link',
          search: { koordinaten: '2' },
          tooltip: { ns: 'coordinatesCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'additional',
          subfield: 'A|v',
          tooltips: [
            { subfield: 'A', ns: 'coordinatesCodes' },
          ],
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '037I': {
      label1: 'ddc_notationen',
      content: [{ ns: 'label2', label2: 'ddc_veraltet' }],
      render: [
        { type: 'text', subfield: 'c' },
        {
          type: 'text',
          subfield: 'd',
          ns: 'ddcNotations',
          tooltips: [{ subfield: 'd', ns: 'ddcNotations' }],
        },
        { type: 'date', subfield: 't|g' },
        { type: 'additional', subfield: 'v' },
      ],
    },
    '041@': {
      label1: 'synonyme',
      content: [{ subfield: '-4' }, { ns: 'relationCodes', relations: '*' }],
      render: [
        { type: 'text', subfield: 'a|g|x' },
        {
          type: 'additional',
          subfield: 'L|U|v|Z',
          tooltips: [
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
          ],
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
      collapseMultipleEntries: true,
    },
    '041A': {
      label1: 'bevorzugte_benennung',
      render: [
        { type: 'text', subfield: 'a|g|x' },
        { type: 'additional', subfield: 'L|U|v|Z' },
      ],
    },
    '041O': {
      label1: 'hinweisdatensatz',
//      content: {
//        ns: 'label2',
//        label2: 'hinweisdatensatz',
//      },
      render: [
        { type: 'link', id: '>9', text: '>name|a' },
        { type: 'additional', subfield: 'v' },
      ],
    },
    '041P': {
      label1: 'andere_normdateien',
      content: [{ subfield: '-4' }, { ns: 'relationCodes', relations: '*' }],
      render: [
        { type: 'link', url: 'u', text: 'a|g|x' },
        { type: 'link', url: '>u', text: '>a|>g|>x' },
        {
          type: 'text',
          subfield: '2|>2',
          tooltips: [{ subfield: '2|>2', ns: 'identifierSources' }],
        },
//        { type: 'additional', subfield: '2|>2', ns: 'identifierSources' }, 
        {
          type: 'link',
          search: { identifikatoren: '2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { gndmul: '>2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'additional',
          subfield: '4|>4|L|U|S|>S|0|>0|v|>v|Z|>Z',
          tooltips: [
            { subfield: '4|>4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
            { subfield: 'S|>S', ns: 'gndmul' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { relationcodes: '>4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '041R': {
      label1: {
        beschreibende_angaben: {
          relations: 'adel|akad|spra|berc|beru|istr',
        },
        chronologie: {
          relations: 'vorg|nach|nazw',
        },
        oberbegriffe: {
          relations: 'obal|obge|obin|obpa',
        },
        thematischer_bezug: {
          relations: 'anla|ausz|feie|rela|stud|them|vbal',
        },
      },
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|g|x' },
        {
          type: 'text',
          subfield: 'a',
          tooltips: [{ subfield: 'a', ns: 'professions' }],
        },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
        {
          type: 'link',
          search: { relation: { code: '4', id: '>9', name: '>name' } },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '042A': {
      label1: 'gnd_systematik',
      render: [
        { type: 'text', subfield: 'a' },
        {
          type: 'link',
          search: { gnd_systematik: 'a' },
          tooltip: { ns: 'gndSystematic', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'a', ns: 'gndSystematic' },
        {
          type: 'info',
          ns: 'gndSystematic',
          url: '_info',
          tooltip: '_info',
        },
      ],
    },
    '042B': {
      label1: 'geografischer_bezug',
      content: {
        ns: 'label2',
        label2: 'laender',
      },
      render: [
        {
          type: 'link',
          id: 'a',
          baseUrl: { subfield: 'a', ns: 'countryCodes' },
          text: 'a',
          tooltip: { ns: 'countryCodes', subfield: 'a' },
        },
        {
          type: 'link',
          search: { land: 'a' },
          tooltip: { ns: 'countryCodes', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'a', ns: 'countryCodes' },
        {
          type: 'info',
          ns: 'countryCodes',
          url: '_info',
          tooltip: '_info',
        },
      ],
    },
    '042C': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'sprachencodes',
      },
      render: [
        { type: 'text', subfield: 'a' },
        {
          type: 'link',
          search: { sprachencode: 'a' },
          tooltip: { ns: 'languageCodes', key: 'searchlinkTooltip' },
        },
        { type: 'additional', subfield: 'a', ns: 'languageCodes' },
        {
          type: 'info',
          ns: 'languageCodes',
          url: '_info',
          tooltip: '_info',
        },
      ],
    },
    '046G': {
      label1: 'publikationen',
      content: [
        {
          subfield: 'f', // record has subfield f
        },
        {
          subfield: '-f', // record does not have subfield f
        },
      ],
      render: [
        { type: 'text', subfield: 'f' },
        { type: 'text', subfield: 'a|b' },
        { type: 'text', subfield: 'w' },
//        {
//          type: 'link',
//          id: 'w',
//          baseUrl: { subfield: 'M_w2', ns: 'identifierSources' },
//          text: 'M_w1',
//        },
        { type: 'link', subfield: 'M', url: 'M', text: 'M' },
        { type: 'text', subfield: '0' },
//        {
//          type: 'link',
//          id: '0',
//          baseUrl: { subfield: 'M', ns: 'identifierSources' },
//          text: 'M',
//        },
      ],
    },
    '047A/03': {
      label1: 'katalogisierende_institution',
      content: {
        ns: 'label2',
        label2: 'isil',
      },
      render: [
        { type: 'text', subfield: 'e' },
        //        {
        //          type: 'link',
        //          id: 'e',
        //          baseUrl: { subfield: 'M', ns: 'identifierSources' },
        //          text: 'e',
        //        },
        //        {
        //          type: 'link',
        //          id: 'r',
        //          baseUrl: { subfield: 'M', ns: 'identifierSources' },
        //          text: 'r',
        //       },
        { type: 'text', subfield: 'r' },
        {
          type: 'info',
          ns: 'organizationCodes',
          url: '_info',
          tooltip: '_info',
        },
      ],
    },
    '047C': {
      label1: {
        titel: { recordType: 'Tu' },
        weitere_angaben: { recordType: 'Ts' },
        namen: { recordType: '-Tu|Ts' },
      },
      content: [
        {
          ns: 'label2',
          label2: 'frueherer_bevorzugter_titel',
          recordType: 'Tu',
        },
        {
          ns: 'label2',
          label2: 'fruehere_bevorzugte_benennung',
          recordType: 'Ts',
        },
        {
          ns: 'label2',
          label2: 'frueherer_bevorzugter_name',
          recordType: '-Tu|Ts',
        },
      ],
      render: [
        { type: 'label', ns: 'label3', subfield: 'S|i' },
        { type: 'text', subfield: 'a|0' },
      ],
      collapseMultipleEntries: true,
    },
    '050D': {
      label1: 'verwendungshinweise',
      content: {
        ns: 'label2',
        label2: 'benutzungshinweis',
      },
      render: [{ type: 'text', subfield: 'a' }],
    },
    '050E': {
      label1: 'quellen_fuer_die_metadaten',
      render: [
        {
          type: 'link',
          id: 'a',
          fixedUrl: { subfield: 'a', ns: 'encyclopediaCodes' },
          text: 'a',
          tooltip: { ns: 'encyclopediaCodes', subfield: 'a' },
        },
        { type: 'additional', subfield: 'b' },
        { type: 'link', url: 'u', text: 'u' },
        {
          type: 'info',
          ns: 'encyclopediaCodes',
          url: '_info',
          tooltip: '_info',
          condition: '-u',
        },
      ],
    },
    '050F': {
      label1: 'quellen_fuer_die_metadaten',
      content: {
        ns: 'label2',
        label2: 'negativ_eingesehene_quellen',
      },
      render: [
        {
          type: 'text',
          subfield: 'a',
          tooltips: [{ subfield: 'a', ns: 'encyclopediaCodes' }],
        },
        {
          type: 'info',
          ns: 'encyclopediaCodes',
          url: '_info',
          tooltip: '_info',
        },
      ],
    },
    '050G': {
      label1: 'weitere_angaben',
      render: [
        { type: 'text', subfield: 'a|b' },
        { type: 'link', url: 'u', text: 'u' },
      ],
    },
    '050H': {
      label1: 'definition',
      render: [{ type: 'text', subfield: 'a' }],
    },
    '060R': {
      label1: 'zeit',
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'text', subfield: 'a|b|c|d' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        // {
        //   type: 'link',
        //   subfield: '5',
        //   id: '5',
        //   baseUrl: { subfield: 'M', ns: 'identifierSources' },
        //   text: '5',
        // },
      ],
    },
    '065@': {
      label1: 'namen',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|g|z|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [
            { subfield: '4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
      collapseMultipleEntries: true,
    },
    '065A': {
      label1: 'namen',
      content: {
        ns: 'label2',
        label2: 'bevorzugter_name',
      },
      render: [
        { type: 'text', subfield: 'a|g|z|x' },
        { type: 'additional', subfield: 'L|U|v|Z' },
      ],
    },
    '065P': {
      label1: 'andere_normdateien',
      content: [{ subfield: '-4' }, { ns: 'relationCodes', relations: '*' }],
      render: [
        { type: 'link', url: 'u', text: 'a|g|z|x' },
        { type: 'link', url: '>u', text: '>a|>g|<z|>x' },
        {
          type: 'text',
          subfield: '2|>2',
          tooltips: [{ subfield: '2|>2', ns: 'identifierSources' }],
        },
        {
          type: 'link',
          search: { identifikatoren: '2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { gndmul: '>2' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
        {
          type: 'additional',
          subfield: '4|>4|L|U|S|>S|0|>0|v|>v|Z|>Z',
          tooltips: [
            { subfield: '4|>4', ns: 'relationCodes' },
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: 'U', ns: 'fontCodes' },
            { subfield: 'S|>S', ns: 'gndmul' },
          ],
        },
        {
          type: 'link',
          search: { relationcodes: '4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          search: { relationcodes: '>4' },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '065R': {
      label1: {
        chronologie: {
          relations: 'vorg|nach|nazw',
        },
        geistige_schoepfer: {
          relations:
            'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
        },
        oberbegriffe: {
          relations: 'obal|obge|obin|obpa',
        },
        thematischer_bezug: {
          relations: 'anla|feie|rela|stud|them|vbal',
        },
        geografischer_bezug: {
          relations:
            '-vorg|nach|nazw|aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd|obal|obge|obin|obpa|anla|feie|rela|stud|them|vbal',
        },
      },
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|g|z|x' },
        {
          type: 'additional',
          subfield: '4|L|U|v|Z',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
        {
          type: 'link',
          search: { relation: { code: '4', id: '>9', name: '>name' } },
          tooltip: { ns: 'relationCodes', key: 'searchlinkTooltip' },
        },
        {
          type: 'link',
          subfield: '5',
          id: '5',
          baseUrl: { subfield: 'M', ns: 'identifierSources' },
          text: '5',
        },
      ],
    },
    '070A/02': {
      label1: 'identifikatoren',
      content: {
        ns: 'label2',
        label2: 'andere_identifikatoren',
      },
      render: [
        { type: 'label', ns: 'label3', subfield: 'S' },
        {
          type: 'link',
          id: '0',
          baseUrl: { subfield: 'S', ns: 'identifierSources' },
          text: '0',
          condition: 'S:DE-Wi17FP',
        },
        {
          type: 'link',
          id: '0',
          fixedUrl: { ns: 'identifierSources', subfield: 'S' },
          text: '0',
          condition: 'S:MVB|S:DE-Mb112',
        },
        {
          type: 'link',
          search: { identifikatoren: 'S' },
          tooltip: { ns: 'identifierSources', key: 'searchlinkTooltip' },
        },
      ],
    },
//    'M06Y': {
//      label1: 'externe_gnd_nutzung',
//      content: [
//        {
//          ns: 'label2',
//          label2: 'entity_facts',
//        },
//        {
//          ns: 'label2',
//          label2: 'publicationen_label2',
//        },
//      ],
//      render: [
//        { type: 'label', ns: 'label3', subfield: 'S' },
//        { type: 'link', url: 'u', text: 'u' },
//        { type: 'additional', subfield: 'z' },
//      ],
//    },
  },
  // $L: Sprachencode (ISO 639-2 B)
  // $U: Schriftcode (ISO 15924) bei nicht-lateinischen Schriftzeichen
  // $T: Feldzuordnung bei nicht-lateinischen Schriftzeichen
  // $5: Institution (=ISIL), die Feld in besonderer Art verwendet
  // $v: Bemerkungen, Regelwerk
  // $Z: Zeitliche Gültigkeit
  // - Original writing (aka font) codes ($L and $U) occur only in 0XX@ and 0XXP
  // and are part of the additional information. Both subfields always occur as
  // the first subfield in a field ($T is ignored). They must be read in the
  // order of their occurrence after the T subfields and before the other
  // subfields of the additional information ($5 $v $Z).
  // - The order of all other 3rd level labels depends on their occurrence in
  // the data set.
  label3: [
    {
      fields: '0***',
      subfields: {
        x: { ns: 'label3', key: 'allgemeine_unterteilung' },
        4: { ns: 'label3', key: 'beziehungskennzeichnung' },
        5: { ns: 'label3', key: 'isil_label3' },
        2: { ns: 'label3', key: 'code_der_quelle' },
        L: { ns: 'label3', key: 'sprache' },
        C: { ns: 'label3', key: 'anwendung' },
        U: { ns: 'label3', key: 'schrift' },
        v: { ns: 'label3', key: 'bemerkungen' },
        Z: { ns: 'label3', key: 'zeitliche_gueltigkeit' },
      },
    },
    {
      fields: '022*',
      subfields: {
        a: { ns: 'label3', key: 'titel_des_werks' },
        '>a': { ns: 'label3', key: 'titel_des_werks' },
        t: { ns: 'label3', key: 'titel_des_werks' },
        '>t': { ns: 'label3', key: 'titel_des_werks' },
        f: { ns: 'label3', key: 'datum' },
        '>f': { ns: 'label3', key: 'datum' },
        h: { ns: 'label3', key: 'inhaltstyp' },
        '>h': { ns: 'label3', key: 'inhaltstyp' },
        l: { ns: 'label3', key: 'sprache' },
        '>l': { ns: 'label3', key: 'sprache' },
        m: { ns: 'label3', key: 'besetzung' },
        '>m': { ns: 'label3', key: 'besetzung' },
        o: { ns: 'label3', key: 'musikarrangement' },
        '>o': { ns: 'label3', key: 'musikarrangement' },
        p: {
          ns: 'label3',
          key: 'titel_des_teils_oder_der_abteilung_des_werks',
        },
        '>p': {
          ns: 'label3',
          key: 'titel_des_teils_oder_der_abteilung_des_werks',
        },
        r: { ns: 'label3', key: 'tonart' },
        '>r': { ns: 'label3', key: 'tonart' },
        s: { ns: 'label3', key: 'version' },
        '>s': { ns: 'label3', key: 'version' },
        n: { ns: 'label3', key: 'zaehlung' },
        g: { ns: 'label3', key: 'zusatz' },
      },
    },
    {
      fields: '028*',
      subfields: {
        P: { ns: 'label3', key: 'persoenlicher_name' },
        '>P': { ns: 'label3', key: 'persoenlicher_name' },
        a: { ns: 'label3', key: 'nachname' },
        '>a': { ns: 'label3', key: 'nachname' },
        d: { ns: 'label3', key: 'vorname' },
        '>d': { ns: 'label3', key: 'vorname' },
        c: { ns: 'label3', key: 'praefix' },
        '>c': { ns: 'label3', key: 'praefix' },
        l: { ns: 'label3', key: 'beiname' },
        '>l': { ns: 'label3', key: 'beiname' },
        n: { ns: 'label3', key: 'zaehlung' },
        g: { ns: 'label3', key: 'zusatz' },
      },
    },
    {
      fields: '029*',
      subfields: {
        a: { ns: 'label3', key: 'hauptkoerperschaft' },
        '>a': { ns: 'label3', key: 'hauptkoerperschaft' },
        b: { ns: 'label3', key: 'untergeordnete_koerperschaft' },
        '>b': { ns: 'label3', key: 'untergeordnete_koerperschaft' },
        n: { ns: 'label3', key: 'zaehlung' },
        g: { ns: 'label3', key: 'zusatz' },
      },
    },
    {
      fields: '030*',
      subfields: {
        a: { ns: 'label3', key: 'hauptkonferenz' },
        '>a': { ns: 'label3', key: 'hauptkonferenz' },
        b: { ns: 'label3', key: 'untergeordnete_einheit' },
        '>b': { ns: 'label3', key: 'untergeordnete_einheit' },
        d: { ns: 'label3', key: 'datum' },
        '>d': { ns: 'label3', key: 'datum' },
        c: { ns: 'label3', key: 'ort' },
        '>c': { ns: 'label3', key: 'ort' },
        n: { ns: 'label3', key: 'zaehlung' },
        g: { ns: 'label3', key: 'zusatz' },
      },
    },
    {
      fields: '041*',
      subfields: {
        g: { ns: 'label3', key: 'zusatz' },
      },
    },
    {
      fields: '041P',
      subfields: {
        a: { ns: 'label3', key: 'begriff' },
        '>a': { ns: 'label3', key: 'begriff' },
      },
    },
    {
      fields: '065*',
      subfields: {
        z: { ns: 'label3', key: 'geografische_unterteilung' },
        '>z': { ns: 'label3', key: 'geografische_unterteilung' },
        g: { ns: 'label3', key: 'zusatz' },
      },
    },
    {
      fields: '0**P',
      subfields: {
        '>x': { ns: 'label3', key: 'allgemeine_unterteilung' },
        '>n': { ns: 'label3', key: 'zaehlung' },
        '>g': { ns: 'label3', key: 'zusatz' },
        '>4': { ns: 'label3', key: 'beziehungskennzeichnung' },
        '>5': { ns: 'label3', key: 'isil_label3' },
        '>2': { ns: 'label3', key: 'code_der_quelle' },
        S: { ns: 'label3', key: 'organisationscode' },
        '>S': { ns: 'label3', key: 'organisationscode' },
        0: { ns: 'label3', key: 'id' },
        '>0': { ns: 'label3', key: 'id' },
        '>v': { ns: 'label3', key: 'bemerkungen' },
        '>Z': { ns: 'label3', key: 'zeitliche_gueltigkeit' },
      },
    },
    {
      fields: '001B',
      subfields: {
        M: { ns: 'label3', key: 'uhrzeit' },
      },
    },
    {
      fields: '003@',
      subfields: {
        0: { ns: 'label3', key: 'ppn' },
      },
    },
    {
      fields: '003U',
      subfields: {
        a: { ns: 'label3', key: 'uri' },
        z: { ns: 'label3', key: 'uri_alt' },
      },
    },
    {
      fields: '006Y',
      subfields: {
        S: {
          ns: 'identifierSources',
          values: '-orcid|isni|wikidata|ror|geonames|opensm|filmport',
        },
      },
    },
    {
      fields: '007N',
      subfields: {
        0: { ns: 'label3', key: 'alte_normnummer' },
        a: { ns: 'oldAuthority', values: 'gnd|gkd|dma|swd|pnd' },
      },
    },
    {
      fields: '007R',
      subfields: {
        0: { ns: 'label3', key: 'gkd_nummer_in_swd' },
      },
    },
    {
      fields: '007W',
      subfields: {
        0: { ns: 'label3', key: 'swd_nummer_in_gkd' },
      },
    },
    {
      fields: '032X',
      subfields: {
        e: { ns: 'label3', key: 'anzahl_der_ensembles' },
        n: { ns: 'label3', key: 'besetzungsstaerke' },
        p: { ns: 'label3', key: 'alternative_besetzung' },
        s: { ns: 'label3', key: 'gesamtbesetzungsstaerke' },
        t: { ns: 'label3', key: 'gesamtanzahl_der_ensembles' },
      },
    },
    {
      fields: '032Y',
      subfields: {
        b: { ns: 'label3', key: 'opus_zaehlung' },
        c: { ns: 'label3', key: 'werksverzeichnis_zaehlung' },
      },
    },
    {
      fields: '037G',
      subfields: {
        d: { ns: 'label3', key: 'determiniertheit' },
        t: { ns: 'label3', key: 'notationsvergabe' },
        g: { ns: 'label3', key: 'letzte_ueberpruefung' },
      },
    },
    {
      fields: '037H',
      subfields: {
        A: { ns: 'label3', key: 'indikator' },
        3: { ns: 'label3', key: 'koordinaten-spezifikation' },
        //         d: { ns: 'label3', key: 'westlichster_laengengrad' },
        //         e: { ns: 'label3', key: 'oestlichster_laengengrad' },
        //         f: { ns: 'label3', key: 'noerdlichster_breitengrad' },
        //         g: { ns: 'label3', key: 'suedlichster_breitengrad' },
      },
    },
    {
      fields: '037I',
      subfields: {
        d: { ns: 'label3', key: 'determiniertheit' },
        t: { ns: 'label3', key: 'notationsvergabe' },
        g: { ns: 'label3', key: 'zeitliche_gueltigkeit_bis' },
      },
    },
    {
      fields: '037L',
      subfields: {
        b: { ns: 'label3', key: 'inhaltstyp_code' },
      },
    },
    {
      fields: '046G',
      subfields: {
        f: { ns: 'label3', key: 'jahr' },
        a: { ns: 'label3', key: 'titel' },
        b: { ns: 'label3', key: 'titelzusatz' },
        w: { ns: 'label3', key: 'katalog_id' },
        M: { ns: 'label3', key: 'url' },
        0: { ns: 'label3', key: 'standardnummer' },
      },
    },
    {
      fields: '047A/03',
      subfields: {
        r: { ns: 'label3', key: 'redaktion' },
      },
    },
    {
      fields: '047C',
      subfields: {
        S: { ns: 'oldAuthority', values: 'gkd|dma|swd|pnd' },
        i: { ns: 'oldAuthority', values: '5|a|c|g|k|p|pt|s|t' },
        a: { ns: 'label3', key: 'alte_ansetzungsform' },
        0: { ns: 'label3', key: 'alte_normnummer' },
      },
    },
    {
      fields: '060R',
      subfields: {
        a: { ns: 'label3', key: 'beginn_einer_zeitspanne' },
        b: { ns: 'label3', key: 'ende_einer_zeitspanne' },
        c: { ns: 'label3', key: 'zeitpunkt' },
        d: { ns: 'label3', key: 'ungefaehre_zeitangabe' },
      },
    },
    {
      fields: '070A/02',
      subfields: {
        S: { ns: 'identifierSources', values: 'DE-Wi17FP|MVB' },
      },
    },
//    {
//      fields: 'M06Y',
//      subfields: {
//        S: { ns: 'identifierSources'},
//        z: { ns: 'letzte_aktualisierung'},
//      },
//    },
  ],
};
